import { useStaticQuery, graphql } from "gatsby"

const useLayoutQuery = () => {
  return useStaticQuery(graphql`
    fragment WpSeo on WpPostTypeSEO {
      metaDesc
      metaKeywords
      metaRobotsNofollow
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        localFile {
          publicURL
        }
      }
      opengraphModifiedTime
      opengraphPublishedTime
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      readingTime
      schema {
        articleType
        pageType
        raw
      }
      title
      twitterDescription
      twitterImage {
        localFile {
          publicURL
        }
      }
      twitterTitle
    }
    fragment ArticleCard on WpPost {
      __typename
      id
      uri
      title
      content
      excerpt
      date(formatString: "ddd DD MMMM")
      sites {
        nodes {
          name
          slug
        }
      }
      types {
        nodes {
          name
          slug
        }
      }
      categories {
        nodes {
          name
          slug
        }
      }
      cardImage: featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 447, 
                height: 290
              )
            }
          }
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
      }
    }
    fragment TeamCard on WpTeam {
      __typename
      id
      uri
      title
      excerpt
      date(formatString: "ddd DD MMMM")
      sites {
        nodes {
          name
          slug
        }
      }
      regions {
        nodes {
          name
          slug
        }
      }
      divisions {
        nodes {
          name
          slug
        }
      }
      categories {
        nodes {
          name
          slug
        }
      }
      acf {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 446, height: 500)
            }
          }
        }
        position
        phone
        email
        linkedin
        content
      }
    }
    fragment MachineCard on WpMachine {
      __typename
      id
      uri
      title
      excerpt
      sites {
        nodes {
          name
          slug
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 700)
            }
          }
        }
      } 
    }
    fragment BrandCard on WpBrand {
      __typename
      id
      uri
      excerpt
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 700)
            }
          }
        }
      }
      sites {
        nodes {
          name
          slug
        }
      }
      brandInfo {
        logo {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
          grayscale: localFile {
            childImageSharp {
              gatsbyImageData(
                width: 400,
                transformOptions: { grayscale: true }
              )
            }
          }
        }
      }
      title
    }
    fragment ResourceCard on WpResource {
      __typename
      id
      title
      excerpt
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
      }
      acf {
        file {
          localFile {
            size
            extension
            publicURL
          }
        }
      }
    }
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      wp {
        siteOptions {
          acf {
            facebook
            instagram
            linkedin
            twitter
            youtube
            announcement
            contactPopupText
            contactPopupFormId
            
            postContactFormText
            postContactFormId

            newZealand {
              operatingHours
              phone
              addresses {
                address
                region
              }
            }
            australia {
              operatingHours
              phone
              addresses {
                address
                region
              }
            }
          }
        }
      }
      headerMenu: wpMenu(locations: {eq: GATSBY_HEADER_MENU}) {
        menuItems {
          nodes {
            label
            uri
          }
        }
      }
      footerMenu: allWpMenuItem(filter: {menu: {node: {locations: {eq: GATSBY_FOOTER_MENU}}}}) { 
        nodes {
          id
          parentId
          label
          uri
          order
          childItems {
            nodes {
              id
              uri
              label
            }
          }
        }
      } 
      machineMenu: allWpMenuItem(filter: {menu: {node: {locations: {eq: GATSBY_MACHINE_MENU}}}}) { 
        nodes {
          id
          parentId
          label
          uri
          order
          childItems {
            nodes {
              id
              uri
              label
            }
          }
        }
      } 
      machinesAu: allWpMachine(
        filter: {
          sites: {
						nodes:{
							elemMatch: { slug: { eq: "australia" } } 
            }
          }
        }
      ) {
        nodes {
          uri
        }
      }
      machinesNz: allWpMachine(
        filter: {
          sites: {
						nodes:{
							elemMatch: { slug: { eq: "new-zealand" } } 
            }
          }
        }
      ) {
        nodes {
          uri
        }
      }
      companyMenu: allWpMenuItem(filter: {menu: {node: {locations: {eq: GATSBY_COMPANY_MENU}}}}) { 
        nodes {
          id
          parentId
          label
          uri
          childItems {
            nodes {
              id
              uri
              label
            }
          }
        }
      } 
    }
  `)
}

export default useLayoutQuery