import React from 'react'
import PropTypes from 'prop-types'

import layoutQuery from "../hooks/use-layout-query"

import '../assets/scss/main.scss'

import Header from '../components/header'
import Footer from '../components/footer'
import { Script } from 'gatsby'

const Layout = ({ children, location, pageContext }) => {
  const data = layoutQuery()

  const [announcement] = React.useState(data.wp.siteOptions.acf.announcement ? true : false)

  React.useEffect(() => {
    document.documentElement.style.setProperty(`--headerHeightMobile`, announcement ? '94px' : '64px');
    document.documentElement.style.setProperty(`--headerHeightDesktop`, announcement ? '134px' : '94px');
    document.documentElement.style.setProperty(`--headerBackground`, pageContext?.header === 'dark' ? '#08141A' : 'transparent');
    if (pageContext?.type !== 'WpPost') {
      document.documentElement.style.setProperty(`--accentColor`, '#00D991');
    }
  }, [announcement, pageContext])

  // pass data to children
  React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      children = React.cloneElement(child, { layoutData: data })
    }
  })

  return (
    <>
      <Header location={location} data={data} pageContext={pageContext} />
      <main>{ children }</main>
      <Footer data={data} />
      <Script id='activecampaign'>
        {`
          (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,https://diffuser-cdn.app-us1.com/diffuser/diffuser.js,"vgo");
          vgo('setAccount', '799013481');
          vgo('setTrackByDefault', true);
          vgo('process');
        `}
      </Script>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
